import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  process.env.NODE_ENV === 'development' ? composeEnhancers(applyMiddleware(thunk)) : applyMiddleware(thunk)
);
// const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
