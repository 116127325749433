import React from 'react';

const ReplaceEvent = () => (
  <div>
    <svg width='17' height='10' viewBox='0 0 17 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M17 5L9.5 0.669873L9.5 9.33013L17 5ZM0 5.75L10.25 5.75V4.25L0 4.25L0 5.75Z' fill='#1A4C96' />
    </svg>
    <svg width='17' height='10' viewBox='0 0 17 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0 5L7.5 9.33013V0.669873L0 5ZM17 4.25L6.75 4.25V5.75L17 5.75V4.25Z' fill='#1A4C96' />
    </svg>
  </div>
);

export default ReplaceEvent;
